import React, { memo } from 'react';
import parse from 'html-react-parser';
import './markdown.css'

function MarkDown ({content, ...restProps}) {
    const html = content?? '<p></p>'
    return (
       <div
        {...restProps}
        id="mark--down-editor"
       >
        {
            parse(html)
        }
       </div>
    )
}

export default memo(MarkDown)