import React, { memo, Fragment, useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router";
import paths from "../../../../helper/pathRoutes";

function DonateComplete() {
	const dispatch = useDispatch();
	const location = useLocation();
	const history = useHistory();

	const onSubmit = (e) => {
		// TODO if send question success -> /success-inquiry
		// dispatch(makeQuestion(setDataQues))
		history.push(paths.successInquiry)
	}
	return (
		<div>
			<div className="m-pager-title">
				<div className="m-pager-title__inner">
					<p className="m-pager-title__inner__title">ポイント交換</p>
				</div>
			</div>

			<div className="t-contents t-contents--bg-ivory" style={{ backgroundColor: "#FFF" }}>
				<div className="o-entry">
					<div
						className="m-animal-header__normal-title u-mb20"
						style={{ color: "#333" }}
					>
						<div className="section--dynamic-exchange-completed">
							<canvas
								style={{
									backgroundImage: "url('..//assets/img/point/img_donate_complete.png')"
								}}
							></canvas>
						</div>
					</div>
					<div className="container">
							<p className="col-md-4 u-fz15">
								アニコム損保では、これからもペット共存社会に
								貢献する様々な社会活動に取り組んでまいります!
							</p>
					</div>

				</div>
			</div>

			{/* </form> */}
			<div className="">
            <div className="t-footer-contents__body">
              <a
                className="a-button a-button--submit a-button--pc-w330 a-button--round u-fz16 js-validation"
                href={paths.myPoint}
              >
                マイページトップに戻る
              </a>
            </div>
          </div>
		</div>
	);
}

export default memo(DonateComplete);
