export const AppReducerType = {
    LANGUAGE: "language",
    MY_POINT: 'myPoint',
    AUTH: 'auth',
    ROUTER: 'router'
}

export const ReduxState = {
    INIT : "init",
    LOADING : "loading",
    LOADED : "loaded",
    ERROR : "error",
    CANCELLED : "cancelled",
    SUCCESS : "success",
}