import React, { memo } from 'react'
import { useSelector } from 'react-redux';
import { Redirect, Route } from "react-router-dom";
import paths from '../helper/pathRoutes';
import { selectIsLogged } from '../modules/auth/redux';

function PrivateRouter({ component: Component, ...restOfProps }) {
  const isAuthenticated = useSelector(selectIsLogged)
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to={paths.login} />
      }
    />
  );
}
export default memo(PrivateRouter)