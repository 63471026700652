const paths = {
    home: "/",
    login: "/login",
    myPoint: '/mypage',
    entry: '/entry',
    entryEdit: '/entry/edit',
    environment: '/environment',
    faq: '/faq',
    point: '/point',
    pointAcquisition: '/point_acquisition',
    pointExchangeItem: '/point_exchange_item',
    provisionalEntry: '/provisional_entry',
    provisionalEntrySuccess: '/provisional-entry-success',
    terms: '/terms',
    verifyRegister: '/verify-register',
    successRegister: '/success-register',
    resetPassword: '/reset-password',
    successResetPassword: '/success-reset-password',
    confirmResetPassword: '/confirm-reset-password',
    verifyResetPassword: '/verify-reset-password',
    setNewPassword: '/set-new-password',
    forgetId: '/forget-id',
    confirmForgetId: '/confirm-forget-id',
    verifyForgetId: '/verify-forget-id',
    successForgetId: '/success-forget-id',
    inquiry: '/inquiry',
    verifyInquiry: '/verify-inquiry',
    successInquiry: '/success-inquiry',
    itemExchangeDetail: '/exchange-item-detail',
    exchangeDetail: '/exchange',
    donateDetail: '/donate',
    exchangeComplete: '/exchange-complete',
    donateComplete: '/donate-complete',
    complete: '/complete',
    closeAccount: '/close-account',
    userProfile: '/profile',
    exchangeVerify: '/exchange-verify',
    donateVerify: '/donate-verify',
    editProfileComplete: '/edit-profile-complete',
    editProfileVerify: '/edit-profile-verify',
    notFound: '/not-found',
    passwordExpired: '/password-expired',
    userExist: '/user-exist',
    anicomHomepage: 'https://www.anicom-sompo.co.jp',
    shippingAddress: '/shipping-address',
  };
  
  export default paths;
  