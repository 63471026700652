export const API_REQUEST_TIMEOUT = 200000; // 20s
export const TOKEN_KEY = "token";
export const REFRESH_TOKEN_KEY = "refresh_token";
export const DEFAULT_WIDGET_REFETCH_INTERVAL = 10000; //10s

export const RESTFUL_URL = process.env.REACT_APP_API_URL || "";
export const BASE_URL_IMAGE = process.env.BASE_URL_IMAGE || "https://anicom-admin.smilesurvey.co/assets/public/anicom"

export const URL_POST_CODE = "https://yubinbango.github.io/yubinbango-data/data"
export const YUBIN = "$yubin"

export const RESPONSE_CODE = {
  TOKEN_EXPIRED: 401,
};

export const BACKGROUD_CODE = {
  home: '#e5e7eb',
  faq: '#fafafa'
};

export const PAGE_SIZE = 4
