import React, { memo } from "react";
import { Link } from 'react-router-dom';

function Terms() {
  return (
    <div>
      <div className="m-pager-title">
        <div className="m-pager-title__inner">
          <p className="m-pager-title__inner__title">モニター規約</p>
        </div>
      </div>
      <div className="t-contents">
        <div className="t-contents__body">
          <div className="u-mb20">
            <p className="u-fwBold">第1条（総則）</p>
            <p>1.	本規約は、アニコム損害保険株式会社（以下「当社」といいます。）が運営する「ani voice（アニボイス）」（以下「当サイト」といいます。）において、アニコムグループ各社　（<a target='_blank' href={'https://www.anicom.co.jp/company/outline/'}>https://www.anicom.co.jp/company/outline/</a>）またはリサーチ・アンケート調査等を希望する事業者（以下「調査依頼事業者」といいます。）のために行うアンケート調査等に関して、モニターと当社との関係を定めるものです。<br></br>
               2.	モニターは本規約の他、本規約の関連規約並びに電子メールまたはウェブサイトへの掲載等（アンケート、マイページへの掲示を含むものとします。）によりモニターに告知されるモニター向けガイダンスを遵守するものとします（モニターに適用される規約等を総称して、以下「本規約等」といいます。）<br></br>
               3.	当サイトのモニターの登録を申請する者は、登録手続を行う前に、本規約等が契約の内容となることを承認のうえ、モニターに申込みます。<br></br>
            </p>
          </div>
          <div className="u-mb20">
            <p className="u-fwBold">第2条（モニター資格）</p>
            <p>1. 本規約等において「モニター」とは、以下の各要件を満たし当社所定の方法によりモニター登録手続きを行い、当サイトのモニターとなることを当社が承諾した者とします。なお、モニターは保険契約者一名に対し、保険契約者または被保険者いずれか一名の登録となります。<br></br>
(1) 本規約に同意すること<br></br>
(2) 当社所定の方法により、当サイトのモニター登録に申込みをし、当社が承諾すること<br></br>
(3) 有効な契約を対象として、当社のペット保険の契約者、被保険者であること<br></br>
被保険者の定義は、保険証券等記載の被保険者（以下、「本人」といいます。）のほか、以下の方が被保険者となります。<br></br>
・本人の配偶者（婚姻の届出をしていないが事実上婚姻関係と同様の事情にある方および戸籍上の性別が同一であるが婚姻関係と異ならない程度の実質を備える状態にある方を含みます。）<br></br>
・本人または配偶者と生計を共にする同居の親族<br></br>
・本人または配偶者と生計を共にする別居の未婚の子<br></br>
 2. 以下のいずれかに該当する場合はモニター登録ができないものとします。<br></br>
(1)未成年である場合<br></br>
(2)同一人が複数のモニター登録手続きをした場合<br></br>
(3)保険契約者の識別IDが重複して登録された場合 <br></br>
</p>
          </div>
          <div className="u-mb20">
            <p className="u-fwBold">第3条（本リサーチサービスの内容）</p>
            <p>１.モニターは、当社から電子メールまたは当サイトのマイページにて配信されるアンケート調査等（ウェブアンケート、グループインタビュー（座談会）、商品テストなど）の依頼に応じ、当社所定の方法による回答・調査を行うものとします。<br></br>
                2.アンケート調査等の中には、モニターの登録情報に従い、その宛先にアンケート調査等の関連資料・モニター商品等を発送することがあります（日本国内に限ります。）。モニターの登録情報の不備が原因でアンケート調査等の関連資料・商品が未着になった場合、当社は責任を一切負わず、再発送も行わないものとします。商品が未着になった場合および国内の宛先を登録していなかった場合は、モニターはアンケート調査等の参加を辞退したものとします。<br></br>
                3.当社より配信されるアンケート調査等の依頼は、調査の目的等に応じて配信されるものであり、すべてのモニターへの配信を約束するものではありません。また、所定の人数を上限として募集する場合、配信されたすべてのモニターにアンケート調査等への回答の権利を約束するものではありません。<br></br>
                4.モニターは、回答するページが当社の管理するウェブサイト以外の外部サイトである場合、調査依頼事業者と直接情報の授受等を行うものとし、当社はその当事者とはならず、モニターに対し一切責任を負いません。万一、情報の授受等に際しトラブルが生じた際には、モニターと調査依頼事業者との間で直接、処理・解決するものとします。<br></br>
                5.配信される情報に関する著作権その他一切の権利は、当社または調査依頼事業者が保有するものであって、情報の提供によりこれらの権利をモニターに与えるものではありません。モニターは、提供された情報を許可なく利用することはできません。<br></br>
                6.アンケート調査等の中には、当社または調査依頼事業者もしくは当社または調査依頼事業者が指定する第三者が製造または販売する製品、サービス（以下「モニター商品等」といいます。）の使用を伴うものがあります。この場合、当該製品の使用またはサービスの利用にあたり、モニターは当該モニター商品等にかかる使用上の注意等を遵守するものとします。モニターは、使用上の注意等をよく読んだ上で、自らの責任において当該モニター商品等を使用するものとします。<br></br>
                7.前項の場合に、モニターは、善良な管理者の注意義務を守ってモニター商品等を使用・管理するものとし、当該モニター商品等の使用が終わった際には、当該モニター商品等の注意事項に従って、返却・処分等するものとします。<br></br>
                <br></br>
                <br></br>
                </p>
          </div>
          <div className="u-mb20">
            <p className="u-fwBold">第4条（ログインID及びパスワードの管理等）</p>
            <p>1.モニターは、本リサーチサービスの利用に際し、当社所定の方法によりログインID（メールアドレス）を登録する必要があります。当社は、本リサーチサービス上で使用されたログインID（メールアドレス）が、モニターのものとして登録されたID（メールアドレス）と一致する場合、モニターによるアクセスとして取扱います。この場合、第三者の使用、不正アクセス等による損害は、モニターが負うものとします。<br></br>
2.モニターは、ログインパスワードを第三者に知られないよう管理し、漏洩等したおそれがある場合などには、当社所定の方法によりログインIDまたはパスワードの変更登録を速やかに行うなど、アカウントの盗用を防止する措置を自らの責任において行うものとします。<br></br>
3.モニターは、アカウントが盗用された場合や第三者に使用されていることが判明した場合には、直ちに当社にその旨を通知するとともに、当社からの指示がある場合にはこれに従うことを予め承諾します。<br></br>
<br></br>
</p>
          </div>
          <div className="u-mb20">
            <p className="u-fwBold">第5条（回答に対する報酬）</p>
            <p>1.当社は、モニターがアンケート調査等において回答情報を提供した報酬として、モニターに対し景品等に交換可能なポイント、または景品等を提供する場合があります。<br></br>
2.抽選または先着順によりモニターの一部にのみ報酬を提供する場合があり、回答したモニター全てに報酬が提供されるものではありません。<br></br>
3.報酬に関する以下の内容については、アンケート調査等ごとに当社が別途定めるものとします。<br></br>
(1) 報酬の内容<br></br>
(2) 報酬の提供方法<br></br>
(3) 報酬の提供期間<br></br>
4.第2項に定める報酬を提供する際の抽選は、当社が定める方法で行うものとします。<br></br>
5.発送の必要な景品等は、モニターの登録情報またはモニターが指定した宛先に発送するものとします（日本国内に限ります。）。モニターの入力情報の不備が原因で景品等が未着になった場合、当社は責任を一切負わず、再発送も行わないものとします。景品等が未着になった場合および国内の宛先を登録していなかった場合は、モニターは景品等を受け取る権利を失うものとします。<br></br>
6.モニターはポイントを累積し、当社指定の方法により指定の景品等と交換することができます。<br></br>
7.ポイントは、ポイントが発行されたモニターに対してのみ有効とし、第三者に対してポイントを譲渡または売買することや複数のログインIDのポイントを合算することは、一切認められないものとします。<br></br>
8.ポイントの有効期間はポイントが付与された月から5年経過後の月末までとします。有効期間が満了したポイントは自動的に失効します。<br></br>
9.第11条の定めに従って退会する場合、当該モニターが既に取得したポイントは、退会成立をもって当社が消失させるものとします。また、モニターが第12条の定めに基づきモニター資格を抹消された場合、当該モニターはモニター資格を喪失し、既に取得したポイントは当社が全て消失させるものとします。<br></br>
10.当社は変更の効力発生日を定めたうえで、一定の予告期間を経て、ポイントの制度を変更、廃止することができるものとします。当該制度を変更する場合、効力発生時期に先立ち、事前に当サイトに掲出する等相当の方法により、モニターに対して予め公表するものとし、モニターは、当サイトでの掲出をもってモニターへの通知及び説明とすることに同意することとします。なお、当該変更、廃止によりモニターに生じた損害については、当社に故意または過失がない限り、当社は法令上の責任を超えて責任を負いません。ただし、当社に軽過失がある場合、その損害賠償額はモニターに現実に生じた通常かつ直接の損害に限るものとします。<br/>
<br/>
</p>
          </div>
          <div className="u-mb20">
            <p className="u-fwBold">第6条（モニターの登録情報）</p>
            <p>1. 当社は、モニターの登録情報に含まれる氏名・住所・電話番号・メールアドレス等モニター個人を識別できる情報ならびに保険契約者の識別IDに紐づく保険契約情報（契約プラン、どうぶつ名、どうぶつの生年月日等）について、当社が別途定める個人情報保護方針（<a target='_blank' href={'https://www.anicom-sompo.co.jp/privacypolicy/'}>https://www.anicom-sompo.co.jp/privacypolicy/</a>） に基づき適切に取り扱うものとします。また、当社は、当該個人情報を第16条及び18条に定める目的に利用するものとします。<br/>
2.モニターは、保険契約者の識別IDに紐づく保険契約情報（契約プラン、どうぶつ名、どうぶつの生年月日等）を当サイトに提供することに同意します。なお、モニターが被保険者の場合、保険契約情報を当サイトに提供することを保険契約者からの同意を得たものとします。<br/>
3.モニターは、当社または調査依頼事業者が、自己の登録情報および回答情報等を、モニターの属性別構成比率、アンケート集計結果、その他の統計情報の形態で第三者に対して開示することに同意します。<br/>
4.モニターが、当サイトに登録した情報に変更が生じた場合、モニターは速やかに変更された情報を登録するものとします。変更された情報の登録をしなかったことにより生じた損害について、当社は一切の責任を負いません。<br/>
5.モニターが情報の変更登録をする前に調査等の手続きが完了したアンケート調査依頼等は変更前の情報に基づいて行われるものとします。<br/>
6.保険契約者の識別IDに紐づく保険契約情報の更新・変更は当社が行い、更新・変更に関するモニターへの通知は行わないものとします。保険契約情報の変更により生じた損害について、当社に故意または過失がない限り、当社は法令上の責任を超えて責任を負いません。ただし、当社に軽過失がある場合、その損害賠償額はモニターに現実に生じた通常かつ直接の損害に限るものとします。<br/>
<br/>
</p>
          </div>
          <div className="u-mb20">
            <p className="u-fwBold">第７条（モニターの禁止行為）</p>
            <p>モニターは、本リサーチサービスの利用にあたり、下記の行為に該当し、または該当する恐れのある行為を行ってはならないものとします。<br/>
1.法令に違反する行為<br/>
2.公序良俗に反する行為<br/>
3.他のモニター、当社、調査依頼事業者、または第三者の著作権、肖像権、プライバシー、財産などを侵害する行為<br/>
4.他のモニター、当社、調査依頼事業者、または第三者を誹謗、中傷する行為<br/>
5.他のモニター、当社、調査依頼事業者、または第三者に不利益を与える行為<br/>
6.本リサーチサービスの運営を妨害する行為<br/>
7.虚偽、または事実に反する情報を登録する行為<br/>
8.虚偽、または事実に反する回答をする行為<br/>
9.同一人物による複数登録、もしくはなりすまし登録をする行為<br/>
10.家族を含めた第三者のために、代理でモニター登録をする行為<br/>
11.家族を含めた第三者と、個別に指定されたID・パスワードを共有、または譲渡する行為<br/>
12.その他、当社が不適当であると認めた行為<br/>
<br/>
</p>
          </div>
          <div className="u-mb20">
            <p className="u-fwBold">第８条（モニターの守秘義務）</p>
            <p>1.モニターは当サイトにおけるアンケート調査等に係る一切の情報について、実際に回答したかどうかを問わず第三者に開示・漏洩してはならないものとします。<br/>
2.当サイトにおけるアンケート調査等を通じて知り得た情報には、アンケート調査等の概要および内容、回答用のインターネット上のページまたは配信する電子メールに含まれる文章、画像、動画、モニターからの問い合わせに対する回答など、すべての情報を含みます。<br/>
3.モニターは別途の定めがない限り、これらの情報を、口頭、書面、電子メール、インターネット上への書き込み等、いかなる手段・方法によっても第三者への開示または漏洩をしてはなりません。<br/>
4.本条に定める守秘義務は、退会またはモニター資格抹消後も継続するものとします。<br/>
<br/>
</p>
          </div>
          <div className="u-mb20">
            <p className="u-fwBold">第９条（権利の帰属）</p>
            <p>1.当社がモニターに対して配信するアンケート調査等に含まれる情報その他の情報に関する著作権その他一切の権利は、当社または調査依頼事業者もしくはその他の第三者が保有するものであって、モニターに対し、かかる権利についていかなる許諾も行うものではありません。モニターは許可なくこれらの情報を利用してはならないものとします。<br/>
2.モニターは、ani voiceにおいて、回答したすべての情報にかかる著作権（著作権法第27条及び第28条の権利を含む）その他一切の権利を当社またはアンケート調査等を行う調査依頼事業者に譲渡するものとし、当社または調査依頼事業者は、かかる情報の複製、修正、編集、第三者への開示、提供を含め、自由に利用、処分することができるものとします。なお、モニターは、当該著作権にかかる著作者人格権を行使しないものとします。<br/>
<br/>
</p>
          </div>
          <div className="u-mb20">
            <p className="u-fwBold">第10条（モニターと当社との連絡方法およびモニターの回答方法）</p>
            <p>1.モニターが当社へ連絡、またはアンケート調査等に対する回答を行う場合は当社が指定する方法により行うこととします。モニターが当社の指定ではない連絡方法や回答方法をとることでモニターに不利益や損害が生じても、当社は一切責任を負わないものとします。<br/>
2.モニターの当社に対する要望に対しては、当社が対応いたしかねる場合があります。<br/>
3.当社からモニターに対する連絡が遅延または不到着等であったことでモニターに不利益や損害が生じても、当社に故意または過失がない限り、当社は法令上の責任を超えて責任を負いません。ただし、当社に軽過失がある場合、その損害賠償額はモニターに現実に生じた通常かつ直接の損害に限るものとします。<br/>
<br/>
</p>
          </div>
          <div className="u-mb20">
            <p className="u-fwBold">第11条（退会） </p>
            <p>1.モニターが、本サイトからの退会を希望する場合、当社所定の手続きに従い、モニターが退会手続きを完了することをもって退会したものとします。<br/>
2.モニターが退会した場合、退会までに行った回答に対する報酬を受け取る権利を失い、また、ポイントについては退会時点の残高は全て無効になるものとし、当社に対して何らの債権を有しないものとします。<br/>
<br/>
</p>
          </div>
          <div className="u-mb20">
            <p className="u-fwBold">第12条（モニター資格の一時停止・抹消）</p>
            <p>1.モニターが以下の事項に該当する場合、当社はモニターの事前承諾なしに、アンケート調査等の依頼の停止、またはモニター資格の一時停止もしくは抹消をすることができるものとします。<br/>
(1) アニコム損害保険株式会社の保険契約期間外になった場合<br/>
(2) 第７条記載の禁止行為を行った場合<br/>
(3) このモニター規約や当社が定める諸規程いずれかに違反した場合<br/>
(4) その他、アンケート調査等の依頼の停止、またはモニター資格の一時停止もしくは抹消をすることが適当であると当社が判断した場合<br/>
(5) 当社が定める方法でモニターへの連絡が一定期間とれなかった場合<br/>
2.当社がアンケート調査等の依頼の停止、モニター資格の一時停止・抹消を行う場合、当社は当該モニターに対して通知する義務も、その措置に関する問い合わせに対して理由などを回答する義務も一切負わないものとします。<br/>
3.モニター資格の抹消の場合、抹消までに行った回答に対する報酬を受け取る権利を失い、また、ポイントについては抹消時点の残高は全て無効になるものとし、当社に対して何らの債権を有しないものとします。<br/>
4.抹消の原因となる事由が生じた時から抹消が適用された時までに付与されたポイント及び景品等に対して、当社は１項に定めるモニター資格抹消に該当する事由発生日以降にモニターが利用したポイントに相当する額及び景品等の返還を求めることができるものとします。<br/>
<br/>
</p>
          </div>
          <div className="u-mb20">
            <p className="u-fwBold">第13条（責任）</p>
            <p>1.アンケート調査等において、サンプル品を用いた試食や試用などを伴う場合、モニターは当社が指定する方法や期間などに従って当該サンプル品を取り扱うものとします。当社が指定した以外の方法や期間等で取り扱ったことによりモニターに不利益、損害などが発生しても、当社に故意または過失がない限り、当社は法令上の責任を超えて責任を負いません。ただし、当社に軽過失がある場合、その損害賠償額はモニターに現実に生じた通常かつ直接の損害に限るものとします。<br/> 
2.通信回線やコンピュータなどの障害によるアンケート調査等の中断、遅延、中止、データの消失、データへの不正アクセスによりモニターに生じた損失、障害などについて、当社に故意または過失がない限り、当社は法令上の責任を超えて責任を負いません。ただし、当社に軽過失がある場合、その損害賠償額はモニターに現実に生じた通常かつ直接の損害に限るものとします。<br/>
3.モニターがこのモニター規約、その他当社が定める諸規程等に違反し当社あるいは第三者に損害を与えた場合、当該モニターは当社の指示があった場合はその指示に従い、かつその損害を賠償するものとし、当社がモニターに代わって第三者または調査依頼事業者の損害を賠償した場合は当該モニターは当社の求償に応じるものとします。<br/>
<br/>
</p>
          </div>
          <div className="u-mb20">
            <p className="u-fwBold">第14条（広告掲載）</p>
            <p>当社は、当サイトにおいて、自社や調査依頼者、および第三者の商品・サービス等に係る広告を掲載することができます。当サイトから、当サイトに関するウェブサイト以外のウェブサイトへのリンクまたは第三者によるウェブサイトへのリンクを提供している場合、当社はリンク先のウェブサイトの利用について、一切責任を負いません。また、当サイトに関するウェブサイトで利用可能な、当社の提供によらないコンテンツ、広告、商品、役務などの利用に起因または関連した生じた一切の損害について、当社に故意または過失がない限り、当社は法令上の責任を超えて責任を負いません。ただし、当社に軽過失がある場合、その損害賠償額はモニターに現実に生じた通常かつ直接の損害に限るものとします。
                <br/></p>
          </div>
          <div className="u-mb20">
            <p className="u-fwBold">第15条（アンケート調査等の内容の変更ならびに一時中断、停止および中止）</p>
            <p>1.当社はいつでも、何らの告知なしに、またモニターの承諾の有無にかかわらず、アンケート調査等の内容の一部もしくは全部を変更、一時中断、停止、中止する場合があります。<br/>
2.前項に基づく変更、一時中断、停止、中止によってモニターに不利益や損害が発生した場合でも、当社に故意または過失のない限り、当社は法令上の責任を超えて責任を負いません。ただし、当社に軽過失がある場合、その損害賠償額はモニターに現実に生じた通常かつ直接の損害に限るものとします。<br/>
<br/>
</p>
          </div>
          <div className="u-mb20">
            <p className="u-fwBold">第16条（個人情報の取扱い）</p>
            <p>1.当社は当社が別途定める個人情報保護方針（<a target='_blank' href={'https://www.anicom-sompo.co.jp/privacypolicy/'}>https://www.anicom-sompo.co.jp/privacypolicy/</a>）に基づきモニターの次の各情報を収集します。<br/>
(1)第６条で定めたモニター情報ならびに保険契約情報<br/>
(2) アンケート調査等においてモニターから取得した回答情報<br/>
(3) モニターからの電話やメールでの問い合わせ等により当社が知り得た情報<br/>
(4) 官報、電話帳、住宅地図等において公開されている情報<br/>
(5) その他、必要な場合に第三者から取得した情報<br/>
2. 当社は、本規約に定める業務の遂行のために、個人情報の適切管理に関する契約等を締結している業務委託先に対し、モニターの個人情報の取り扱いを委託することがあります。<br/>
3.当社は第１項記載の個人情報、アンケート回答内容等の分析結果について、当社の個人情報保護方針に記載の目的の範囲内で利用します。<br/>
<br/>
</p>
          </div>
          <div className="u-mb20">
            <p className="u-fwBold">第17条（匿名加工情報の取扱い）</p>
            <p>1. 弊社は、匿名加工情報（特定の個人を識別できず且つ復元できないように個人情報を加工した情報）を作成する場合には、以下の対応を行います。<br/>
(1)法令で定める基準に従って、適正な加工を施すこと<br/>
(2)法令で定める基準に従って、匿名加工情報の作成に用いた個人情報から削除した情報や加工の方法に関する情報の漏えいを防止するために安全管理措置を講じること<br/>
(3)法令で定める基準に従って、作成した匿名加工情報に含まれる個人に関する情報の項目を公表すること<br/>
(4)匿名加工情報の作成の元となった個人情報に係る本人を識別する目的で、他の情報を照合しないこと<br/>
2.当社は、調査依頼事業者に匿名加工情報を提供する場合には、法令で定める基準に従って、提供しようとする匿名加工情報に含まれる個人に関する情報の項目及びその提供の方法を公表するとともに、提供先となる調査依頼事業者に対して、提供する情報が匿名加工情報であることを明示いたします。<br/>
<br/>
</p>
          </div>
          <div className="u-mb20">
            <p className="u-fwBold">第18条（個人情報の共同利用）</p>
            <p>当社およびアニコムグループ各社は、目的達成に必要な範囲で、以下の通りモニターの個人情報の共同利用を行います。<br/>
1.共同利用する情報の項目<br/>
（1）第６条で定めたモニター情報ならびに保険契約情報<br/>
（2）当社が実施するアンケート調査等に対してモニターが回答した、または意見や感想などとして提供した情報。<br/>
2.共同利用者の範囲<br/>
アニコムグループ各社<a target='_blank' href={'https://www.anicom.co.jp/company/outline/'}>https://www.anicom.co.jp/company/outline/</a> のうち、当社によるアンケート調査等の結果を利用する会社<br/>
3. 共同利用の目的<br/>
当社および共同利用するグループ各社が個人情報保護方針において公表する利用目的<br/>
4.共同利用の管理責任者<br/>
アニコム損害保険株式会社　<a target='_blank' href={'https://www.anicom-sompo.co.jp/company/profile/'}>https://www.anicom-sompo.co.jp/company/profile/</a>
<br/>
<br/>
</p>
          </div>
          <div className="u-mb20">
            <p className="u-fwBold">第19条（退会したモニターの個人情報の取扱い）</p>
            <p>退会したモニターの個人情報は、退会後１年間の保管期間を経過後、順次削除するものとします。
            <br/>
            </p>
          </div>
          <div className="u-mb20">
            <p className="u-fwBold">第20条（個人情報の開示・訂正・利用停止等）</p>
            <p>1.モニターは、当社に対して、個人情報の保護に関する法律に定めるところにより自己に関する個人情報を開示・訂正・利用停止等するよう請求することができます。<br/>
2.当社が保有する自己に関する個人情報の開示・訂正・利用停止等を求める場合には第21条記載の窓口に連絡してください。請求手続きの詳細について回答いたします。<br/>
<br/>
</p>
          </div>
          <div className="u-mb20">
            <p className="u-fwBold">第21条（個人情報の取扱いに関する問い合わせ窓口）</p>
            <p>1.個人情報の開示・訂正・利用停止等については、以下の【個人情報の『開示』等請求手続き】をご覧ください。<br/>
            <a target='_blank' href={'https://www.anicom-sompo.co.jp/privacypolicy/privacypolicy_req/'}>https://www.anicom-sompo.co.jp/privacypolicy/privacypolicy_req/</a><br/>
            2.個人情報に関する問い合わせやその他の意見のお申し出については、以下の【お問い合わせ】をご覧ください。<br/>
            <a target='_blank' href={'https://www.anicom-sompo.co.jp/inquiry/'}>https://www.anicom-sompo.co.jp/inquiry/</a><br/>
            </p>
          </div>
          <div className="u-mb20">
            <p className="u-fwBold">第22条（反社会的勢力の排除）</p>
            <p>1.モニターは、自己が暴力団員、暴力団関係企業、暴力団関係者、その他の反社会的勢力（以下「反社会的勢力」といいます。）に該当しないことを表明し、将来にわたっても該当しないことを確約するものとします。<br/>
2.モニターが前項に違反した場合、当社は事前に通知せずに当該モニターのモニター資格の抹消を行うことができるものとします。この場合、当該モニターに損害が生じても当社は一切責任を負いません。<br/>
<br/>
</p>
          </div>
          <div className="u-mb20">
            <p className="u-fwBold">第23条（規約の改正）</p>
            <p>1.当社は、このモニター規約を改定することができるものとします。当社がこのモニター規約を変更する場合、変更の内容または変更されたモニター規約およびその効力発生時期を、事前に当サイトに掲出する等相当の方法により、モニターに対して予め公表します。また、当社はこのモニター規約に付随する規約を任意に新たに制定することができ、その付随する規約はこのモニター規約に含まれるものとします。なお、モニターは、ウェブサイトでの掲出をもってモニターへの通知及び説明とすることに同意することとします。<br/>
2.前項における、このモニター規約の改正および付随する規約の制定は、当社が当サイトにおいて告知する変更日より効力が生じるものとします。モニターは改正後のモニター規約および新たに制定された付随する規約に従うものとします。<br/>
<br/>
</p>
          </div>
          <div className="u-mb20">
            <p className="u-fwBold">第24条（準拠法、合意管轄）</p>
            <p>1.モニターの居住地およびアンケート調査等の実施場所に関わらず、このモニター規約および付随する規約等の準拠法は日本法とし、各条項は日本法および日本における慣習、条理によって解釈されるものとします。<br/>
2.このモニター規約等に関する一切の訴訟は、訴額に応じて、東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。<br/>
<br/>
</p>
          </div>


          <div className="u-mb20">
            <p>
						  附則<br/>
              2022年4月1日　制定<br/>
              2024年8月1日　改定<br/>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(Terms);
